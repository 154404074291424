import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Row, Col } from '../components/global'
import PageCard from '../components/home/pageCard'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container container-last">
      <div className="text-center">
        <h1>NOT FOUND</h1>
        <p>Sorry, you just hit a page that doesn&#39;t exist... Try the pages below.</p>
      </div>
      <hr />
      <Row>
        <Col width="32%">
          <PageCard
            icon="technicians"
            title="For Technicians"
            description="Learn about our technician training programs in electrical systems troubleshooting and electronics repair."
            url="/technicians"
          />
        </Col>
        <Col width="32%">
          <PageCard
            icon="shop-owners"
            title="For Shop Owners"
            description="Our training programs can train your technicians to be more proficient in electrical repair - saving labor hours, down time and fewer good parts replaced."
            url="/shop-owners"
          />
        </Col>
        <Col width="32%">
          <PageCard
            icon="teachers"
            title="For Teachers"
            description='We have the electrical-electronics curriculum that your students will need when they enter the job market. Be "THAT" teacher that showed them how!'
            url="/teachers"
          />
        </Col>
      </Row>
    </div>
  </Layout>
)

export default NotFoundPage
