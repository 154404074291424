import React from 'react'
import { Link } from 'gatsby'
import { css } from 'emotion'
import config from '../../siteConfig'
import Icon from '../global/icon'

const containerStyle = css({
  textAlign: 'center',
  maxWidth: '25em',
  margin: 'auto',
})

const titleStyle = css({
  color: config.theme.colors.darkGrey,
  textTransform: 'uppercase',
  letterSpacing: '-0.02em',
  fontSize: '1.5rem',
  margin: '0.25rem 0 0.5rem',
})

const descriptionStyle = css({
  color: config.theme.colors.medGrey,
  fontSize: '1rem',
  lineHeight: '1.3',
  marginBottom: '0.5rem',
})

const PageCard = props => {
  return (
    <div className={containerStyle}>
      <Icon icon={props.icon} />
      <h2 className={titleStyle}>{props.title}</h2>
      <p className={descriptionStyle}>{props.description}</p>
      <p>
        <Link to={props.url}>Learn More &rsaquo;</Link>
      </p>
    </div>
  )
}

export default PageCard
